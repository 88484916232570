@if (checkoutSession()) {
  <trlp-payment
    [stripeKey]="stripeKey"
    [amount]="amount"
    [currency]="currency"
    (completePayment)="completedPayment($event)"
    [returnUrl]="returnUrl()"></trlp-payment>
} @else if (webinerDetailsData.isPending()) {
  <dashboard-content-container header="Content">
    <ng-container content-action> </ng-container>
    <ng-container content-inner>
      <div>loading...</div>
    </ng-container>
  </dashboard-content-container>
} @else if (webinerDetailsData.isError()) {
} @else {
  @if (webinerDetailsData.data(); as webinerDetails) {
    <dashboard-content-container header="{{ webinerDetails.title }}">
      <ng-container content-action>
        @if (
          isCreator() ||
          !!(
            webinerDetails.price === 0 ||
            (webinerDetails?.participants?.length ?? 0 > 0)
          )
        ) {
          <button mat-raised-button color="primary" (click)="joinLiveWebiner()">
            {{ isLiveWebiner() ? 'Back to Details' : 'Start Event' }}
          </button>
        }
        @if (isCreator()) {
          <button
            mat-raised-button
            color="primary"
            (click)="openParticipantDrawer()">
            Participants
          </button>
        }
        @if (!isCreator()) {
          <button
            mat-raised-button
            (click)="checkout()"
            [disabled]="!!(webinerDetails?.participants?.length ?? 0 > 0)"
            color="primary"
            class="pay-button">
            {{
              (webinerDetails?.participants?.length ?? 0 > 0)
                ? 'Already Enrolled'
                : 'Enroll Now'
            }}
          </button>
        } @else {
          @if (isCreator()) {
            <button
              mat-raised-button
              color="primary"
              class="primary-button"
              [routerLink]="['./../', eventId(), 'edit']">
              Edit Event
            </button>
          }
        }
      </ng-container>
      <ng-container content-inner>
        @if (isLiveWebiner()) {
          <div class="event-live-container">
            <trueleap-plus-live-webiner
              [meetingCode]="getMeetingCode()"></trueleap-plus-live-webiner>
          </div>
        } @else {
          <div class="event-details-container">
            <div class="event-preview">
              <img [src]="thumbnail()" class="event-image" />
              <div class="event-details">
                <div class="event-price-tag">
                  <div class="price-text">
                    @if (webinerDetails.price > 0) {
                      Price: ${{ webinerDetails.price }}
                    } @else {
                      FREE EVENT
                    }
                  </div>
                  <mat-chip-listbox aria-label="Event Tags" class="event-tags">
                    @for (tag of webinerDetails.tagToEvents; track tag) {
                      <mat-chip-option color="primary">{{
                        tag.tag.name
                      }}</mat-chip-option>
                    }
                  </mat-chip-listbox>
                  <div class="date-range">
                    <mat-icon color="primary">{{
                      webinerDetails.endDate ? 'date_range' : 'event'
                    }}</mat-icon>
                    {{ webinerDetails.startDate | date: 'mediumDate' }}
                    {{ webinerDetails.endDate ? '-' : '' }}
                    {{ webinerDetails.endDate | date: 'mediumDate' }}
                  </div>
                  @if (rruledata(); as rrule) {
                    <div class="frequency-day">
                      @if (rrule.endDate && rrule.frequency) {
                        <mat-icon color="primary">view_week</mat-icon>
                        {{ rrule.frequency }} on
                        {{ rrule.daysOfWeek.join(' | ') }}
                      }
                    </div>
                    <div class="event-time">
                      <mat-icon color="primary">schedule</mat-icon>
                      {{ webinerDetails.startTime }} to
                      {{ webinerDetails.endTime }}
                    </div>
                  }
                  <div class="date-range">
                    <mat-icon color="primary">public</mat-icon>
                    {{ webinerDetails.timeZone }}
                  </div>
                </div>
                @if (isCreator()) {
                  <div class="event-action">
                    <mat-slide-toggle
                      [(ngModel)]="webinerDetails.isPublished"
                      (change)="updatePublish($event)"
                      labelPosition="after"
                      >Publish Event</mat-slide-toggle
                    >
                    <mat-slide-toggle
                      [(ngModel)]="webinerDetails.isPublic"
                      [disabled]="!webinerDetails.isPublished"
                      (change)="updatePublic($event)"
                      labelPosition="after"
                      >Add Event to Marketplace</mat-slide-toggle
                    >
                  </div>
                }
              </div>
            </div>
            <div class="course-extra-container">
              <div class="course-details-container">
                <div class="course-tabs-container">
                  <mat-tab-group
                    mat-stretch-tabs="false"
                    mat-align-tabs="start">
                    <mat-tab label="Host">
                      <div class="tabs-content">
                        <div class="tab-header">
                          Host: {{ webinerDetails.authorInfo.firstname }}
                          {{ webinerDetails.authorInfo.lastname }}
                        </div>
                        <div class="author-info">
                          {{ webinerDetails.authorInfo.bio }}
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Overview">
                      <div class="tabs-content">
                        <div class="tab-header">Event Description:</div>
                        <div
                          class="description-content"
                          [innerHTML]="webinerDetails.description"></div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </div>
          </div>
        }
      </ng-container>
    </dashboard-content-container>
  }
}
