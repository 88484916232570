/* eslint-disable prefer-const */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  CourseDetails,
  EventAuthor,
  eventUpdateData,
  isPublic,
  isPublished,
  Modules,
  PaymentObj,
  Tag,
  WebinerDetails,
  WebinerParticipant,
  WebinerParticipants,
  WebinerPayload,
} from '@trueleap/ui-kit';
import { BASE_API_URL } from '@trueleap/ui-tokens';
import { parseISO, set } from 'date-fns';
import { rrulestr } from 'rrule';
import { Observable, map } from 'rxjs';
import { formatDate } from 'date-fns';

export interface TimeZone {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
}
@Injectable({
  providedIn: 'root',
})
export class WebinerService {
  #http = inject(HttpClient);
  #baseURl = inject(BASE_API_URL);

  get marketplaceResourceURI(): string {
    return `${this.#baseURl}/marketplace/event`;
  }
  get resourceURI(): string {
    return `${this.#baseURl}/event`;
  }
  get myResourceURI(): string {
    return `${this.#baseURl}/marketplace/my-event`;
  }
  get resourceTagURI(): string {
    return `${this.#baseURl}/tag`;
  }
  get resourceCourseURI(): string {
    return `${this.#baseURl}/course`;
  }
  get paymentInitiate(): string {
    return `${this.#baseURl}/marketplace/payment`;
  }
  // Creator APIs
  fetchEvents(
    sortOptions?: string,
    filterOptions?: string
  ): Observable<WebinerDetails[]> {
    let sortString = '';
    if (sortOptions !== '') {
      switch (sortOptions) {
        case 'POPULARITY':
        case 'AGE':
          sortString = `?sortBy=${sortOptions?.toLowerCase()}`;
          break;
        case 'PRICE-ASC':
          sortString = `?sortBy=price&sortOrder=asc`;
          break;
        case 'PRICE-DESC':
          sortString = `?sortBy=price&sortOrder=desc`;
          break;
        default:
          break;
      }
    }
    return this.#http
      .get<{
        data: WebinerDetails[];
      }>(
        this.resourceURI +
          sortString +
          `${sortString !== '' ? '&' : ''}${filterOptions}`
      )
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }

  fetchEventByID(id: string): Observable<WebinerDetails> {
    return this.#http
      .get<{
        data: WebinerDetails;
      }>(`${this.resourceURI}/${id}`)
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }

  fetchParticipants(id: string): Observable<WebinerParticipants[]> {
    return this.#http
      .get<{
        data: WebinerParticipants[];
      }>(`${this.resourceURI}/${id}/participant`)
      .pipe(
        map(r => {
          return r.data.filter((part: any) => part.role !== 'HOST');
        })
      );
  }
  addWebinerParticipant(
    webinerId: string,
    data: WebinerParticipant
  ): Observable<WebinerParticipant> {
    return this.#http.post<any>(
      `${this.resourceURI}/${webinerId}/participant`,
      data
    );
  }
  // marketplace APIs
  fetchMarketplaceEvents(params?: {
    subdomain?: string;
    sortBy?: string;
    sortOrder?: string;
    author?: string[];
    tags?: string[];
    startDate?: string | null;
    endDate?: string | null;
    limit?: number;
  }): Observable<WebinerDetails[]> {
    let httpParams = new HttpParams();
    console.log(params);

    if (params?.subdomain) {
      httpParams = httpParams.set('domain', params?.subdomain);
    }
    if (params?.sortBy) {
      httpParams = httpParams.set('sortBy', params?.sortBy);
    }
    if (params?.sortOrder) {
      httpParams = httpParams.set('sortOrder', params?.sortOrder);
    }

    if (params?.author && params?.author.length > 0) {
      httpParams = httpParams.set('author', params?.author.join(','));
    }
    if (params?.tags && params?.tags.length > 0) {
      httpParams = httpParams.set('tags', params?.tags.join(','));
    }
    if (params?.limit) {
      httpParams = httpParams.set('pageSize', params.limit);
    }
    if (params?.startDate) {
      httpParams = httpParams.set(
        'starDate',
        formatDate(params?.startDate, 'yyyy-MM-dd')
      );
    }
    if (params?.endDate) {
      httpParams = httpParams.set(
        'endDate',
        formatDate(params?.endDate, 'yyyy-MM-dd')
      );
    }
    return this.#http
      .get<{
        data: WebinerDetails[];
      }>(this.marketplaceResourceURI, {
        params: httpParams,
      })
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }
  fetchCalendarEvents() {
    return this.#http.get(this.marketplaceResourceURI).pipe(
      map((response: any) => {
        return response.data.flatMap((event: any) => {
          const occurrences = this.generateEventOccurence(event);
          return occurrences;
        });
      })
    );
  }
  fetchMarketplaceEventsById(id: string): Observable<WebinerDetails> {
    return this.#http
      .get<{
        data: WebinerDetails;
      }>(`${this.marketplaceResourceURI}/${id}`)
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }
  fetchCourseModules(id: string): Observable<Modules[]> {
    return this.#http
      .get<{
        data: Modules[];
      }>(`${this.resourceURI}/${id}/module`)
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }
  addWebiner(data: WebinerPayload): Observable<WebinerPayload> {
    return this.#http.post<any>(this.resourceURI, data);
  }
  updateEvent(
    eventId: string,
    data: WebinerPayload
  ): Observable<WebinerPayload> {
    return this.#http.put<any>(`${this.resourceURI}/${eventId}`, data);
  }
  addCourseWebiner(courseId: string, data: Modules): Observable<Modules> {
    return this.#http.post<any>(`${this.resourceURI}/${courseId}/module`, data);
  }
  initiatePayment(data: PaymentObj): Observable<string> {
    return this.#http.post<any>(`${this.paymentInitiate}/initiate`, data);
  }
  fetchCourses(): Observable<CourseDetails[]> {
    return this.#http
      .get<{
        data: CourseDetails[];
      }>(this.resourceCourseURI)
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }
  fetchMyEvents(): Observable<WebinerDetails[]> {
    return this.#http
      .get<{
        data: any[];
      }>(this.myResourceURI)
      .pipe(
        map(r => {
          return r.data.map(ev => {
            return { ...ev.events, authorInfo: ev.users };
          });
        })
      );
  }
  fetchEventAuthors(): Observable<EventAuthor[]> {
    return this.#http
      .get<{
        data: EventAuthor[];
      }>(this.marketplaceResourceURI + '-authors')
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }
  parseTime(timeString: string): { hours: number; minutes: number } {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return { hours, minutes };
  }
  generateEventOccurence(event: WebinerDetails) {
    try {
      const { rrule, startTime, endTime } = event;

      if (!rrule || !startTime || !endTime) {
        console.error('Missing required event details:', event);
        return [];
      }

      const rule = rrulestr(rrule);

      let occurrences;
      if (rrule.includes('UNTIL')) {
        occurrences = rule.all();
      } else {
        const dtStartMatch = rrule.match(/DTSTART:(\d{8}T\d{6}Z?)/);
        if (!dtStartMatch) {
          console.error('DTSTART not found in rrule:', rrule);
          return [];
        }
        const dtStart = dtStartMatch[1];
        occurrences = [parseISO(dtStart)];
      }

      const { hours: startHours, minutes: startMinutes } =
        this.parseTime(startTime);
      const { hours: endHours, minutes: endMinutes } = this.parseTime(endTime);

      const events = occurrences.map(occurrence => {
        const start = set(parseISO(occurrence.toISOString()), {
          hours: startHours,
          minutes: startMinutes,
        });
        const end = set(parseISO(occurrence.toISOString()), {
          hours: endHours,
          minutes: endMinutes,
        });

        return {
          id: event.id,
          title: event.title,
          start: start.toISOString(),
          end: end.toISOString(),
          authorInfo: event.authorInfo,
          thumbnail: event.thumbnail,
          description: event.description,
        };
      });

      return events;
    } catch (error) {
      console.error('Error generating event occurrences', error);
      return [];
    }
  }

  // tags api

  fetchTags(): Observable<Tag[]> {
    return this.#http
      .get<{
        data: Tag[];
      }>(this.resourceTagURI)
      .pipe(
        map(r => {
          return r.data;
        })
      );
  }

  addTag(tag: string): Observable<Tag> {
    return this.#http.post<any>(this.resourceTagURI, { name: tag });
  }

  getAllRecordings() {
    return this.#http.get<any>(`${this.resourceURI}/recordings`).pipe(
      map(r => {
        return r.data;
      })
    );
  }

  getRecordingAssets(recordingId: string) {
    return this.#http
      .get<any>(`${this.resourceURI}/recordings/${recordingId}`)
      .pipe(
        map(r => r.data?.filter((asset: any) => asset?.metadata?.resolution))
      );
  }
  // Update API
  publishEvent(
    eventId: string,
    data: eventUpdateData
  ): Observable<CourseDetails> {
    return this.#http.put<any>(`${this.resourceURI}/${eventId}`, data);
  }

  addToMarketplace(
    eventId: string,
    data: eventUpdateData
  ): Observable<CourseDetails> {
    return this.#http.put<any>(`${this.resourceURI}/${eventId}`, data);
  }
  getTimeZones(): Observable<TimeZone[]> {
    return this.#http.get<TimeZone[]>(`assets/json/timezones.json`);
  }
}
