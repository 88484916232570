import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { EventDetailsDialogComponent } from '@trueleap/ui-kit';
import { lastValueFrom } from 'rxjs';
import { WebinerService } from '../webiner.service';
@Component({
    selector: 'trueleap-plus-event-calendar',
    imports: [CommonModule, FullCalendarModule],
    templateUrl: './event-calendar.component.html',
    styleUrl: './event-calendar.component.scss'
})
export class EventCalendarComponent {
  webinerService = inject(WebinerService);
  mtxDialog = inject(MtxDialog);
  eventData = injectQuery(() => ({
    queryKey: ['calendar-event'],
    queryFn: () => lastValueFrom(this.webinerService.fetchCalendarEvents()),
    retry: 0,
  }));
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    eventClick: arg => this.handleEventClick(arg),
  };
  handleEventClick(info: any) {
    info.jsEvent.preventDefault();
    const dialogRef = this.mtxDialog.originalOpen(EventDetailsDialogComponent, {
      width: '550px',
      data: { eventInfo: info.event, user: 'CONSUMER' },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
