import { Component, computed, inject, signal } from '@angular/core';
import {
  injectQuery,
  injectQueryClient,
} from '@tanstack/angular-query-experimental';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, tap } from 'rxjs';
import { WebinerService } from './webiner.service';
import { PaymentComponent } from '@trueleap/payment';
import {
  ContentContainer,
  ContentInner,
  getPublicImageDownloadUrl,
} from '@trueleap/ui-kit';
import { AuthStore } from '@trueleap/auth';
import { rrulestr, RRule } from 'rrule';
import { format, isAfter, isBefore } from 'date-fns';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { WebinerLiveComponent } from './webiner-live.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'trueleap-plus-public-webiner-details',
  imports: [
    PaymentComponent,
    ContentInner,
    ContentContainer,
    MatIconModule,
    MatChipsModule,
    MatTabsModule,
    MatButtonModule,
    WebinerLiveComponent,
    DatePipe,
  ],
  template: `
    <div class="main-container">
      @if (checkoutSession()) {
        <trlp-payment
          [stripeKey]="stripeKey"
          [amount]="amount"
          [currency]="currency"
          (completePayment)="completedPayment($event)"
          [returnUrl]="returnUrl()"></trlp-payment>
      } @else if (webinerDetailsData.isPending()) {
        <dashboard-content-container header="Loading...">
          <ng-container content-inner>
            <div>loading...</div>
          </ng-container>
        </dashboard-content-container>
      } @else if (webinerDetailsData.isError()) {
      } @else {
        @if (webinerDetailsData.data(); as webinerDetails) {
          <dashboard-content-container header="{{ webinerDetails.title }}">
            <ng-container content-inner>
              <div class="inner-container">
                @if (isLiveWebiner()) {
                  <div class="event-meeting-back">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="joinLiveWebiner()">
                      Back To Details
                    </button>
                  </div>
                }
                <div class="event-container">
                  @if (isLiveWebiner()) {
                    <div class="event-live-container">
                      <trueleap-plus-live-webiner
                        [meetingCode]="
                          getMeetingCode()
                        "></trueleap-plus-live-webiner>
                    </div>
                  } @else {
                    <div class="event-description">
                      <div class="event-details-container">
                        <div class="event-tabs-container">
                          <mat-tab-group
                            dynamicHeight
                            mat-stretch-tabs="false"
                            mat-align-tabs="start">
                            <mat-tab label="Host">
                              <div class="tabs-content">
                                <div class="tab-header">
                                  Host:
                                  {{ webinerDetails.authorInfo.firstname }}
                                  {{ webinerDetails.authorInfo.lastname }}
                                </div>
                                <div class="author-info">
                                  <!-- get authorinfo from db -->
                                  {{ webinerDetails.authorInfo.bio }}
                                </div>
                              </div>
                            </mat-tab>
                            <mat-tab label="Overview">
                              <div class="tabs-content">
                                <div class="tab-header">Event Description:</div>
                                <div
                                  class="description-content"
                                  [innerHTML]="
                                    webinerDetails.description
                                  "></div>
                              </div>
                            </mat-tab>
                          </mat-tab-group>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="event-preview">
                    <img [src]="thumbnail()" class="event-image" />
                    <div class="event-details">
                      <div class="event-schedule date-range">
                        <mat-icon color="primary">{{
                          webinerDetails.endDate ? 'date_range' : 'event'
                        }}</mat-icon>
                        {{ webinerDetails.startDate | date: 'mediumDate' }}
                        {{ webinerDetails.endDate ? '-' : '' }}
                        {{ webinerDetails.endDate | date: 'mediumDate' }}
                      </div>
                      @if (rruledata(); as rrule) {
                        <div class="event-schedule frequency-day">
                          @if (webinerDetails.endDate && rrule.frequency) {
                            <mat-icon color="primary">view_week</mat-icon>
                            {{ rrule.frequency }} on
                            {{ rrule.daysOfWeek.join(' | ') }}
                          }
                        </div>
                        <div class="event-schedule event-time">
                          <mat-icon color="primary">schedule</mat-icon>
                          {{ webinerDetails.startTime }} to
                          {{ webinerDetails.endTime }}
                        </div>
                      }
                      <div class="event-schedule event-time">
                        <mat-icon color="primary">public</mat-icon>
                        {{ webinerDetails.timeZone }}
                      </div>
                      <mat-chip-listbox
                        aria-label="Event Tags"
                        class="event-tags">
                        @for (tag of webinerDetails.tagToEvents; track tag) {
                          <mat-chip-option color="primary">{{
                            tag.tag.name
                          }}</mat-chip-option>
                        }
                      </mat-chip-listbox>
                      <div class="event-action">
                        <span class="price-text">
                          @if (webinerDetails.price > 0) {
                            Price: {{ '$ ' + webinerDetails.price }}
                          } @else {
                            FREE EVENT
                          }
                        </span>
                        @if (this.user()?.id !== webinerDetails.author) {
                          <button
                            mat-raised-button
                            (click)="checkout()"
                            [disabled]="
                              hasEventExpired() ||
                              !!(webinerDetails?.participants?.length ?? 0 > 0)
                            "
                            class="secondary-button">
                            {{
                              hasEventExpired()
                                ? 'Event Expired'
                                : (webinerDetails?.participants?.length ??
                                    0 > 0)
                                  ? 'Already Enrolled'
                                  : this.isPaymentSuccess()
                                    ? 'Processing...'
                                    : 'ENROLL NOW'
                            }}
                          </button>
                        }
                      </div>
                      @if (
                        this.user()?.id &&
                        (webinerDetails?.participants?.length ?? 0 > 0) &&
                        !isLiveWebiner() &&
                        !hasEventExpired()
                      ) {
                        <div class="event-meeting">
                          <button
                            mat-raised-button
                            class="primary-button event-button"
                            (click)="joinLiveWebiner()">
                            Join Event
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </dashboard-content-container>
        }
      }
    </div>
  `,
  styles: [
    `
      @import 'mixins';
      .main-container {
        .inner-container {
          padding: 0 1.25rem;
        }
        .event-container {
          display: flex;
          align-items: stretch;
          width: 100%;
          gap: 1.5rem;
          margin: 4rem 0;
          .event-description {
            width: 70%;
            border-radius: 0.75rem;
            padding: 1.5rem;
            -webkit-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
          }
          .event-preview {
            width: 30%;
            border-radius: 0.75rem;
            padding: 1.5rem;
            -webkit-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .event-image {
              width: 100%;
              border-radius: 4px;
              object-fit: cover;
              object-position: center;
              aspect-ratio: 16/9;
            }
            .event-details {
              padding: 1.5rem 0;
              .event-schedule {
                display: flex;
                align-items: center;
                gap: 1rem;
              }
            }
            .event-action {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 1.25rem;
              .price-text {
                color: #4e4e4e;
                text-align: center;
                @include font('DM sans', 20px, 700, normal, normal);
                text-transform: uppercase;
              }
            }
          }
        }
        .tabs-content {
          padding: 1.5rem 0;
          .tab-header {
            @include font('DM sans', 24px, 600, normal, normal);
          }
          .author-info {
            padding-top: 1.75rem;
            @include font('DM sans', 16px, 400, normal, normal);
          }
          .description-content {
            padding-top: 1.75rem;
            @include font('DM sans', 16px, 400, normal, normal);
          }
        }
        .event-meeting-back {
          margin-top: 1.25rem;
        }
        .event-button {
          width: 100%;
          margin-top: 0.5rem;
        }
        .event-live-container {
          width: 70%;
        }
      }
    `,
  ],
})
export class PublicWebinerDetailsComponent {
  route = inject(ActivatedRoute);
  webinerService = inject(WebinerService);
  user = inject(AuthStore).profile;
  router = inject(Router);
  mtxDialog = inject(MtxDialog);
  queryClient = injectQueryClient();
  checkoutSession = signal<boolean>(false);
  eventId = signal<string>('');
  returnUrl = signal<string[]>([]);
  isLiveWebiner = signal<boolean>(false);
  isPaymentSuccess = signal<boolean>(false);
  breadcrumbService = inject(BreadcrumbService);
  showLocalTime = signal<boolean>(false);
  hasEventExpired = signal<boolean>(false);
  stripeKey = '';
  amount = 0;
  currency = '';
  constructor() {
    this.route.params.subscribe(params => {
      this.eventId.set(params['id']); // Access the 'id' parameter from the URL
      this.returnUrl.set(['events', this.eventId()]);
    });
  }
  webinerDetailsData = injectQuery(() => ({
    queryKey: ['market-webiner-details', this.eventId()],
    queryFn: () =>
      lastValueFrom(
        this.webinerService.fetchMarketplaceEventsById(this.eventId()).pipe(
          tap(data => {
            this.breadcrumbService.set('@eventDetails', data.title);
            const webinerEndTime = data.endDate
              ? this.addTimeStringToDate(new Date(data.endDate), data.endTime)
              : this.addTimeStringToDate(
                  new Date(data.startDate),
                  data.endTime
                );

            const timeDifference =
              this.localTimeZone() - Number(data.timeZoneOffset);
            const adjustedWebinerEndTime = new Date(
              webinerEndTime.getTime() + timeDifference * 60 * 60 * 1000
            );
            if (isAfter(new Date(), adjustedWebinerEndTime)) {
              this.hasEventExpired.update(() => true);
            }
          })
        )
      ),
    refetchOnWindowFocus: false,
    enabled: !!this.eventId(),
  }));
  thumbnail = computed(() => {
    const webinerDetails = this.webinerDetailsData.data();
    return webinerDetails?.thumbnail
      ? getPublicImageDownloadUrl(webinerDetails?.thumbnail)
      : 'assets/vid-player.jpg';
  });
  localTimeZone = computed(() => {
    const userTimezoneOffset = new Date().getTimezoneOffset();
    const hoursOffset = -userTimezoneOffset / 60;
    return hoursOffset;
  });

  async completedPayment(event: any) {
    if (event) {
      this.checkoutSession.set(false);
      this.isPaymentSuccess.update(() => true);
      setTimeout(async () => {
        await this.queryClient.invalidateQueries({
          queryKey: ['market-webiner-details', this.eventId()],
        });
      }, 3000);
    }
  }
  checkout() {
    if (this.user()) {
      this.webinerService
        .initiatePayment({
          type: 'EVENT',
          resourceId: this.eventId(),
        })
        .subscribe(
          (res: any) => {
            if (!res.data.amount || res.data.amount === 0) {
              this.queryClient.invalidateQueries({
                queryKey: ['market-webiner-details', this.eventId()],
              });
            } else {
              this.stripeKey = res.data.clientSecret;
              this.amount = res.data.amount;
              this.currency = res.data.currency;
              this.checkoutSession.set(true);
            }
          },
          err => {
            console.error(err);
            this.checkoutSession.set(false);
          }
        );
    } else {
      this.router.navigate(['auth'], {
        queryParams: {
          returnUrl: this.router.url,
        },
      });
    }
  }
  rruledata = computed(() => {
    const data = this.webinerDetailsData.data();
    if (!data?.rrule) {
      return null;
    }
    const rruleObj = rrulestr(data.rrule);
    return {
      frequency: rruleObj.origOptions.freq
        ? this.getFrequency(rruleObj.origOptions.freq)
        : null,
      daysOfWeek: rruleObj.origOptions.byweekday
        ? this.extractDaysFromRrule(data.rrule)
        : [],
    };
  });
  getLocalTime(
    startDate: string,
    startTime: string,
    timeZoneOffset: string
  ): string {
    // Parse startDate
    const date = new Date(startDate);

    // Extract hours and minutes from startTime (e.g., "9:00 AM")
    const [time, meridian] = startTime.split(' ');
    const [hoursStr, minutesStr] = time.split(':');
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    // Convert to 24-hour format if needed
    if (meridian === 'PM' && hours !== 12) hours += 12;
    if (meridian === 'AM' && hours === 12) hours = 0;

    // Set the time on the date
    date.setUTCHours(hours, minutes, 0, 0);

    // Apply the timezone offset (convert to local time based on the offset)
    const localDate = new Date(
      date.getTime() + Number(timeZoneOffset) * 60 * 60 * 1000
    );

    // Return the local time as a string in the user's local timezone
    return localDate.toLocaleString(undefined, { timeZoneName: 'short' });
  }

  getFrequency(freq: number): string {
    switch (freq) {
      case 0:
        return 'Yearly';
      case 1:
        return 'Monthly';
      case 2:
        return 'Weekly';
      case 3:
        return 'Daily';
      case 4:
        return 'Hourly';
      case 5:
        return 'Minutely';
      case 6:
        return 'Secondly';
      default:
        return 'Unknown';
    }
  }

  extractDaysFromRrule(rruleString: string): string[] {
    // Extract the RRULE part from the string
    const rrulePart = rruleString.split('RRULE:')[1];
    if (!rrulePart) {
      return [];
    }

    // Split the RRULE string by semicolons
    const components = rrulePart.split(';');
    const bydayComponent = components.find(component =>
      component.startsWith('BYDAY')
    );

    let days: string[] = [];
    if (bydayComponent) {
      days = bydayComponent.split('=')[1].split(',');
    }

    // Map of RRULE days to full names
    const dayMap: { [key: string]: string } = {
      MO: 'Monday',
      TU: 'Tuesday',
      WE: 'Wednesday',
      TH: 'Thursday',
      FR: 'Friday',
      SA: 'Saturday',
      SU: 'Sunday',
    };

    return days.map(day => dayMap[day]);
  }
  private addTimeStringToDate(date: Date, timeStr: string): Date {
    const timeReg = /(\d+)[.|:](\d+)\s?(\w+)/;
    const parts = timeStr.match(timeReg);
    if (!parts) return date;
    const hours = /am/i.test(parts[3])
      ? (function (am) {
          return am < 12 ? am : 0;
        })(parseInt(parts[1], 10))
      : (function (pm) {
          return pm < 12 ? pm + 12 : 12;
        })(parseInt(parts[1], 10));

    const minutes = parseInt(parts[2], 10);

    date.setHours(hours);

    date.setMinutes(minutes);
    date.setSeconds(0);

    return date;
  }
  joinLiveWebiner() {
    const webinerDetails = this.webinerDetailsData.data();
    if (!webinerDetails) return;
    const webinerStartTime = webinerDetails.startDate
      ? this.addTimeStringToDate(
          new Date(webinerDetails.startDate),
          webinerDetails.startTime
        )
      : this.addTimeStringToDate(new Date(), webinerDetails.startTime);

    const webinerEndTime = webinerDetails.endDate
      ? this.addTimeStringToDate(
          new Date(webinerDetails.endDate),
          webinerDetails.endTime
        )
      : this.addTimeStringToDate(
          new Date(webinerDetails.startDate),
          webinerDetails.endTime
        );

    const timeDifference =
      this.localTimeZone() - Number(webinerDetails.timeZoneOffset);

    const adjustedWebinerStartTime = new Date(
      webinerStartTime.getTime() + timeDifference * 60 * 60 * 1000
    );
    const adjustedWebinerEndTime = new Date(
      webinerEndTime.getTime() + timeDifference * 60 * 60 * 1000
    );

    if (isBefore(new Date(), adjustedWebinerStartTime)) {
      return this.mtxDialog.alert(
        'Event Not Started',
        'Event has not started yet. Please check back later.'
      );
    }

    if (isAfter(new Date(), adjustedWebinerEndTime)) {
      return this.mtxDialog.alert(
        'Event Ended',
        'Event has already ended. Please check out other events.'
      );
    }
    this.isLiveWebiner.set(!this.isLiveWebiner());
  }
  getMeetingCode = computed(() => {
    const participants = this.webinerDetailsData.data()?.participants || [];
    if (participants.length > 0) {
      switch (participants[0].role) {
        case 'HOST':
          return this.webinerDetailsData.data()!.meetingHostCode;
        case 'CO_HOST':
          return this.webinerDetailsData.data()!.meetingCoHostCode;
        case 'GUEST':
          return this.webinerDetailsData.data()!.meetingGuestCode;
        default:
          return this.webinerDetailsData.data()!.meetingGuestCode;
      }
    } else {
      return this.webinerDetailsData.data()!.meetingHostCode;
    }
  });
}
