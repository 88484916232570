import { CommonModule } from '@angular/common';
import { Component, Inject, computed, inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { EditorModule } from '@tinymce/tinymce-angular';
import { PageHeaderComponent } from '@trueleap/ui-kit';
import { UserService } from '@trueleap/users';
import { QuillEditorComponent } from 'ngx-quill';
import { lastValueFrom } from 'rxjs';
export interface ParticipantData {
  participants: string[];
}

@Component({
    selector: 'trueleap-plus-add-participant',
    templateUrl: './add-participant.component.html',
    styleUrl: './add-participant.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EditorModule,
        MatFormFieldModule,
        MatOptionModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatSlideToggleModule,
        PageHeaderComponent,
        QuillEditorComponent,
        NgSelectModule,
        MatDialogModule,
    ]
})
export class AddParticipantWebinerComponent {
  ROLES = [
    {
      value: 'CO_HOST',
      name: 'Co-host',
    },
    {
      value: 'GUEST',
      name: 'Guest',
    },
  ];
  userSerice = inject(UserService);
  usersData = injectQuery(() => ({
    queryKey: ['users'],
    queryFn: () => lastValueFrom(this.userSerice.fetchUsers()),
    refetchOnWindowFocus: false,
  }));
  usersFiltered = computed(() => {
    const users = this.usersData.data();
    return users?.filter(user => !this.data.participants.includes(user.id));
  });
  constructor(
    public dialogRef: MatDialogRef<AddParticipantWebinerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ParticipantData
  ) {
    console.log(data.participants);
  }
  participantForm = new UntypedFormGroup({
    userId: new UntypedFormControl('', Validators.required),
    role: new UntypedFormControl('', Validators.required),
  });

  cancel() {
    this.dialogRef.close({
      isCancel: true,
      data: null,
    });
  }
  onContentChanged(event: any) {
    console.log(event);
  }
  save() {
    this.dialogRef.close({
      isCancel: false,
      data: this.participantForm.value,
    });
  }
}
