import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { NgSelectModule } from '@ng-select/ng-select';
import { injectQuery } from '@tanstack/angular-query-experimental';
import {
  ContentCardComponent,
  ContentContainer,
  ContentInner,
  PageHeaderComponent,
  WebinerCardComponent,
} from '@trueleap/ui-kit';
import { lastValueFrom } from 'rxjs';
import { WebinerService } from '../webiner.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'trueleap-plus-my-webiner',
    imports: [
        CommonModule,
        WebinerCardComponent,
        MatChipsModule,
        NgSelectModule,
        ContentContainer,
        ContentInner,
        MatIconModule,
    ],
    templateUrl: './my-webiner.component.html',
    styleUrl: './my-webiner.component.scss'
})
export class MyWebinerComponent {
  isCreator = input<boolean>(false);
  webinerService = inject(WebinerService);
  webinerQueries = injectQuery(() => ({
    queryKey: ['my-events'],
    queryFn: () => lastValueFrom(this.webinerService.fetchMyEvents()),
  }));
}
