import { CommonModule } from '@angular/common';
import { Component, inject, NgZone, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { MtxDrawer, MtxDrawerModule } from '@ng-matero/extensions/drawer';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { AuthStore } from '@trueleap/auth';
import {
  ContentAction,
  ContentContainer,
  ContentInner,
  EventDetailsDialogComponent,
  PageHeaderComponent,
  WebinerCardComponent,
} from '@trueleap/ui-kit';
import { lastValueFrom } from 'rxjs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { WebinerService } from '../webiner.service';
import { WebinerFilterComponent } from '../webiner-filter/webiner-filter.component';
import { EventCalendarComponent } from '../event-calendar/event-calendar.component';
import { formatDate } from 'date-fns';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
type DateFilter = {
  startDate?: string | null;
  endDate?: string | null;
};

@Component({
    selector: 'trlp-webiners',
    imports: [
        ContentInner,
        ContentContainer,
        ContentAction,
        CommonModule,
        WebinerCardComponent,
        PageHeaderComponent,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        MtxDrawerModule,
        MatButtonToggleModule,
        FullCalendarModule,
        MatDialogModule,
        MatSelectModule,
        NgSelectModule,
        FormsModule,
    ],
    templateUrl: './webiners.component.html',
    styleUrl: './webiners.component.scss'
})
export class WebinersComponent {
  mtxDrawer = inject(MtxDrawer);
  webinerService = inject(WebinerService);
  authStore = inject(AuthStore);
  mtxDialog = inject(MtxDialog);
  matDialog = inject(MatDialog);
  sortSignal = signal<string>('POPULARITY');
  filterSignal = signal<string>('');
  ngZone = inject(NgZone);
  dataView = signal<string>('GRID');
  authorFilterSignal = signal<string[]>([]);
  dateFilterSignal = signal<DateFilter | null>(null);
  tagsFilterSignal = signal<string[]>([]);
  sortOption = [
    { value: 'POPULARITY', viewValue: 'Popularity' },
    { value: 'PRICE-ASC', viewValue: 'Price- Low to High' },
    { value: 'PRICE-DESC', viewValue: 'Price- High to Low' },
    { value: 'AGE', viewValue: 'Newest First' },
  ];
  webinerQueries = injectQuery(() => ({
    queryKey: ['events'],
    queryFn: () =>
      lastValueFrom(
        this.webinerService.fetchEvents(this.sortSignal(), this.filterSignal())
      ),
  }));
  eventCalendarData = injectQuery(() => ({
    queryKey: ['calendar-events'],
    queryFn: () => lastValueFrom(this.webinerService.fetchCalendarEvents()),
  }));
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    eventClick: arg => this.handleEventClick(arg),
  };
  previewCalendar() {
    this.mtxDrawer.open(EventCalendarComponent, {
      width: '30vw',
      hasBackdrop: true,
      data: this.webinerQueries.data(),
    });
  }
  changeDataView(event: string) {
    this.dataView.set(event);
  }

  handleEventClick(info: any) {
    info.jsEvent.preventDefault();
    const dialogRef = this.mtxDialog.originalOpen(EventDetailsDialogComponent, {
      width: '550px',
      data: { eventInfo: info.event, user: 'ADMIN' },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  onSortChange(event: string) {
    this.sortSignal.set(event);
    this.ngZone.run(() => {
      this.webinerQueries.refetch();
    });
  }
  setFilters(filter: any) {
    const filterStringArray: string[] = [];
    if (filter?.author?.length > 0) {
      this.authorFilterSignal.set(filter?.author);
      filterStringArray.push(`author=${filter.author.join(',')}`);
    } else {
      this.authorFilterSignal.set([]);
    }
    if (filter?.categories?.length > 0) {
      this.tagsFilterSignal.set(filter?.categories);
      filterStringArray.push(`tags=${filter.categories.join(',')}`);
    } else {
      this.tagsFilterSignal.set([]);
    }
    if (filter?.startDate && filter?.endDate) {
      this.dateFilterSignal.set({
        startDate: filter?.startDate,
        endDate: filter?.endDate,
      });
      filterStringArray.push(
        `startDate=${formatDate(filter?.startDate, 'yyyy-MM-dd')}&endDate=${formatDate(filter?.endDate, 'yyyy-MM-dd')}`
      );
    } else {
      this.dateFilterSignal.set(null);
    }
    this.ngZone.run(() => {
      this.filterSignal.set(filterStringArray.join('&'));
      this.webinerQueries.refetch();
    });
  }
  openDialog() {
    const dialogRef = this.matDialog.open(WebinerFilterComponent, {
      width: '100vw',
      maxWidth: '100vw',
      data: {
        author: this.authorFilterSignal(),
        tags: this.tagsFilterSignal(),
        date: this.dateFilterSignal(),
      },
      position: {
        top: `15.5rem`,
        left: `7.3rem`,
      }, // Optional: Set the width of the dialog
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data) {
        this.setFilters(result.data);
      }
    });
  }
}
