import { CommonModule } from '@angular/common';
import { Component, Inject, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import {
  injectMutation,
  injectQuery,
} from '@tanstack/angular-query-experimental';
import { AuthStore } from '@trueleap/auth';
import { WebinerParticipant } from '@trueleap/ui-kit';
import { lastValueFrom } from 'rxjs';
import { AddParticipantWebinerComponent } from '../add-participant/add-participant.component';
import { WebinerService } from '../webiner.service';

export type ParticipantRefData = {
  isCancel: boolean;
  data: any;
};
@Component({
    selector: 'trueleap-plus-webiner-participants',
    imports: [
        CommonModule,
        RouterModule,
        MatTableModule,
        MatSlideToggleModule,
        MatIconModule,
        FormsModule,
        MatButtonModule,
    ],
    templateUrl: './webiner-participants.component.html',
    styleUrl: './webiner-participants.component.scss'
})
export class WebinerParticipantsComponent {
  ROLES: { [key: string]: string } = {
    HOST: 'Host',
    CO_HOST: 'Co-host',
    GUEST: 'Guest',
  };
  route = inject(ActivatedRoute);
  webinerService = inject(WebinerService);
  dialog = inject(MatDialog);
  isCreator = inject(AuthStore).isCreator;
  webinerId = '';
  participantsData = injectQuery(() => ({
    queryKey: ['webiner-participants', this.webinerId],
    queryFn: () =>
      lastValueFrom(this.webinerService.fetchParticipants(this.webinerId)),
    refetchOnWindowFocus: false,
  }));
  cohostData = computed(() => {
    const data = this.participantsData.data();
    if (!data) {
      return [];
    }

    return data.filter(participant => {
      return participant.role === 'CO_HOST';
    });
  });
  guestData = computed(() => {
    const data = this.participantsData.data();
    if (!data) {
      return [];
    }

    return data.filter(participant => {
      return participant.role === 'GUEST';
    });
  });
  mutation = injectMutation(client => ({
    mutationKey: ['webiner-participants', this.webinerId],
    mutationFn: (data: WebinerParticipant) =>
      lastValueFrom(
        this.webinerService.addWebinerParticipant(this.webinerId, data)
      ),
    onSuccess: async () => {
      return await client.invalidateQueries({
        queryKey: ['webiner-participants', this.webinerId],
      });
    },
  }));
  constructor(
    @Inject(MTX_DRAWER_DATA) public data: any,
    public drawerRef: MtxDrawerRef<WebinerParticipantsComponent>
  ) {
    this.webinerId = data.eventId; // Access the 'id' parameter from the URL
  }
  updateStatus(event: any) {
    console.log(event);
  }
  onClose(): void {
    this.drawerRef.dismiss();
  }
  openAddParticipant() {
    const dialogRef = this.dialog.open(AddParticipantWebinerComponent, {
      width: '50vw',
      height: 'auto',
      panelClass: 'custom-dialog-container',
      hasBackdrop: true,
      data: {
        participants: this.participantsData.data()?.map(par => par.userId),
      },
    });

    dialogRef.afterClosed().subscribe((result: ParticipantRefData) => {
      if (!result.isCancel) {
        const payload = result.data;
        this.mutation.mutate(result.data);
      }
    });
  }
}
