import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { ContentContainer, ContentInner } from '@trueleap/ui-kit';
import { derivedAsync } from 'ngxtension/derived-async';
import { WebinerService } from './webiner.service';

@Component({
    selector: 'event-recordings',
    imports: [
        ContentContainer,
        ContentInner,
        MatListModule,
        MatIconModule,
        DatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <dashboard-content-container header="Recorded Events">
      <ng-container content-inner>
        <div class="recording-list-container">
          <div class="recording-list">
            <mat-list>
              <div mat-subheader>Event Recordings</div>
              @for (recording of recordings(); track recording.id) {
                <mat-list-item (click)="playVideo(recording)">
                  <mat-icon matListItemIcon>videocam</mat-icon>
                  <div matListItemTitle>
                    Recording_video_{{
                      recording.created_at | date: 'MMM dd, yyyy hh:mm aa'
                    }}
                  </div>
                </mat-list-item>
              } @empty {
                <mat-list-item>
                  <mat-icon matListItemIcon>folder_limited</mat-icon>
                  <div matListItemTitle>No recordings found</div>
                </mat-list-item>
              }
            </mat-list>
          </div>
        </div>
      </ng-container>
    </dashboard-content-container>
  `,
    styles: [
        `
      .recording-list-container {
        width: 100%;
        height: 100%;
        display: flex;
        overflow: auto;

        .recording-list {
          flex-grow: 1;
          padding: 1rem;
        }
      }
    `,
    ]
})
export class EventRecordingDetails {
  eventService = inject(WebinerService);
  route = inject(ActivatedRoute);
  recordings = derivedAsync(
    () =>
      this.eventService.getRecordingAssets(
        this.route.snapshot.params['recordingId']
      ),
    {
      initialValue: [],
    }
  );

  playVideo(asset: any) {
    const { url } = asset.preSignedURL;
    window.open(url, '_blank');
  }
}
