import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MtxCheckboxGroup } from '@ng-matero/extensions/checkbox-group';
import { NgSelectModule } from '@ng-select/ng-select';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { lastValueFrom } from 'rxjs';
import { WebinerService } from '../webiner.service';

type Category = {
  id: number;
  name: string;
};
type Star = number;

@Component({
    selector: 'trueleap-plus-public-webiner-filter',
    imports: [
        CommonModule,
        NgSelectModule,
        MatCardModule,
        MatDividerModule,
        MatExpansionModule,
        ReactiveFormsModule,
        FormsModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MtxCheckboxGroup,
        NgSelectModule,
    ],
    templateUrl: './public-webiner-filter.component.html',
    styleUrl: './public-webiner-filter.component.scss'
})
export class PublicWebinerFilterComponent implements OnInit {
  fb = inject(FormBuilder);
  webinerService = inject(WebinerService);
  authorOpenState = false;
  ratingOpenState = false;
  categoryOpenState = false;
  filterForm: FormGroup;
  selectedAuthor?: (string | null)[] | null;
  selectedStar?: (number | null)[] | null;
  selectedCategory?: (number | null)[] | null;
  applyFilter = output<any>();
  eventAuthorData = injectQuery(() => ({
    queryKey: ['event-author'],
    queryFn: () => lastValueFrom(this.webinerService.fetchEventAuthors()),
  }));
  eventTagsData = injectQuery(() => ({
    queryKey: ['event-tags'],
    queryFn: () => lastValueFrom(this.webinerService.fetchTags()),
  }));
  ngOnInit(): void {
    this.filterForm = this.fb.group({
      author: this.fb.control([]),
      categories: this.fb.control([]),
      startDate: this.fb.control(null),
      endDate: this.fb.control(null),
    });
  }
  filter() {
    this.applyFilter.emit(this.filterForm.getRawValue());
  }
}
