import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentContainer, ContentInner } from '@trueleap/ui-kit';
import { derivedAsync } from 'ngxtension/derived-async';
import { WebinerService } from './webiner.service';

@Component({
    selector: 'event-recordings',
    imports: [
        ContentContainer,
        ContentInner,
        MatListModule,
        MatIconModule,
        DatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <dashboard-content-container header="Recorded Events">
      <ng-container content-inner>
        <div class="recording-list-container">
          <div class="recording-list">
            <mat-list>
              <div mat-subheader>Event Recordings</div>
              @for (recording of recordings(); track recording.id) {
                <mat-list-item
                  (click)="
                    openRecordings(recording.id, recording.recording.id)
                  ">
                  <mat-icon matListItemIcon>folder</mat-icon>
                  <div matListItemTitle>{{ recording.title }}</div>
                  <div matListItemLine>
                    {{ recording.recording.stopped_at | date }}
                  </div>
                </mat-list-item>
              } @empty {
                <mat-list-item>
                  <mat-icon matListItemIcon>folder_limited</mat-icon>
                  <div matListItemTitle>No recordings found</div>
                </mat-list-item>
              }
            </mat-list>
          </div>
        </div>
      </ng-container>
    </dashboard-content-container>
  `,
    styles: [
        `
      .recording-list-container {
        width: 100%;
        height: 100%;
        display: flex;
        overflow: auto;

        .recording-list {
          flex-grow: 1;
          padding: 1rem;
        }
      }
    `,
    ]
})
export class EventRecordings {
  eventService = inject(WebinerService);
  recordings = derivedAsync(() => this.eventService.getAllRecordings(), {
    initialValue: [],
  });
  router = inject(Router);
  route = inject(ActivatedRoute);

  openRecordings(eventId: string, recordingId: string) {
    this.router.navigate([eventId, 'recording', recordingId], {
      relativeTo: this.route,
    });
  }
}
